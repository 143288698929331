import { Typography, Table, Tag } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { CloseOutlined } from "@ant-design/icons";
import { useRef } from "react";
import Calculator from "../Components/Calculator";
import { useAuthContext } from "../Contexts/AuthContext";

const Transactions2 = () => {
    const { Title } = Typography;
    const [show, setShow] = useState(false);
    const [orders, setOrders] = useState([]);
    const [id, setId] = useState("");
    const [showInfo, setShowInfo] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const status = useRef("false");
    const [loading, setLoading] = useState(false);
    const { amountInp, gets, total } = useAuthContext();

    const FetchOrders = async () => {
        try {
            const { data } = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/orders/allcryptoorders",
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: Cookies.get("token"),
                    },
                }
            );
            
            setOrders(data.orders);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        FetchOrders();
    }, []);

    const data = [
        ...orders.map((elem, i) => {
            return {
                id: elem._id,
                userId: elem.userId,
                orderId: elem.orderId,
                name: elem.name,
                beneficiary: elem.beneficiary,
                date: elem.date,
                status: [elem.status],
                gets: elem.gets,
                totalAmount: elem.total,
                amount: elem.amount,
            };
        }),
    ];

    const columns = [
        {
            title: "Order Id",
            dataIndex: "orderId",
            key: "orderId",
        },
        {
            title: "CriptoMoneda",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Benificiario",
            dataIndex: "benef",
            key: "benef",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, { status }) => (
                <>
                    {status.map((stats) => {
                        let color;
                        if (
                            stats.toUpperCase() === "PENDIENTE" ||
                            stats.toUpperCase() === "ANULADA"
                        ) {
                            color = "volcano";
                        } else if (stats.toUpperCase() === "RECIBIDO") {
                            color = "blue";
                        } else if (stats.toUpperCase() === "COMPLETADA") {
                            color = "green";
                        } else {
                            color = "yellow";
                        }
                        return (
                            <Tag color={color} key={stats}>
                                {stats.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
        },
    ];

    const dataSource = [
        ...data.map((elem, i) => {
            return {
                orderId: "WV" + elem.orderId,
                name: elem.name,
                benef: elem.beneficiary,
                status: elem.status,
                date: elem.date,
                actions: (
                    <>
                        <button
                            className="btn btn-primary fs-6 me-3"
                            onClick={() => {
                                setShow(true);
                                setId(elem);
                            }}
                        >
                            Change Status
                        </button>
                        <button
                            className="btn btn-warning fs-6 ms-3"
                            onClick={async (e) => {
                                e.target.innerHTML = "Loading...";
                                setId(elem);
                                setShowInfo(true);
                                status.current = elem.payment;
                                e.target.innerHTML = "Details";
                            }}
                        >
                            Details
                        </button>
                        <button
                            className="btn btn-danger fs-6 ms-3"
                            onClick={async (e) => {
                                e.target.innerHTML = "Loading...";
                                try {
                                    const confirmm = window.confirm("Are You sure?");
                                    if (confirmm) {
                                        await axios.post(process.env.REACT_APP_BASE_URL + "/api/orders/deletecriptoorder",
                                            {
                                                id: elem.id
                                            },
                                            {
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    token: Cookies.get("token"),
                                                },
                                            }
                                        )
                                        FetchOrders()
                                    }
                                } catch (error) {

                                }
                                e.target.innerHTML = "Delete";
                            }}
                        >
                            Delete
                        </button>
                    </>
                ),
            };
        }),
    ];

    const HandleStatus = async (status) => {
        const confirmm = window.confirm(
            `Are you sure you want to change status to : ${status}`
        );
        if (confirmm) {
            document.getElementById("bg_status").style.pointerEvents = "none";
            try {
                await axios.post(
                    process.env.REACT_APP_BASE_URL + `/api/orders/criptostatus`,
                    {
                        status: status,
                        id: id.id,
                        orderId: id.orderId.replace("WV", ""),
                        user: id.userId,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            token: Cookies.get("token"),
                        },
                    }
                );
                FetchOrders();
                document.getElementById("bg_status").style.pointerEvents = "all";
                setShow(false);
            } catch (err) {
                console.log(err);
                document.getElementById("bg_status").style.pointerEvents = "all";
            }
        }
    };

    return (
        <>
            <div>
                <Title level={window.matchMedia("(max-width: 600px)").matches ? 4 : 3}>
                    All Recent Transactions
                </Title>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    className="responsiveTable transactions"
                />

                {show && (
                    <div className="bg_status" id="bg_status">
                        <div
                            className="d-flex justify-content-center flex-column
             status_modal"
                            style={{ position: "relative" }}
                        >
                            <p className="fs-5 fw-bold">Change Status :</p>
                            <CloseOutlined
                                style={{
                                    fontSize: "1.3rem",
                                    position: "absolute",
                                    top: "15px",
                                    right: "10px",
                                }}
                                onClick={() => setShow(false)}
                            />

                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div
                                    className="status_box red d-flex justify-content-center 
                align-items-center text-white"
                                    type="button"
                                    onClick={() => {
                                        setLoading(true);
                                        HandleStatus("PENDIENTE");
                                        setLoading(false);
                                    }}
                                    aria-disabled={loading}
                                >
                                    PENDIENTE
                                </div>
                                <div
                                    className="status_box blue d-flex justify-content-center
                 align-items-center text-white"
                                    type="button"
                                    onClick={() => {
                                        setLoading(true);
                                        HandleStatus("RECIBIDO");
                                        // moneiTokenHandler();
                                        setLoading(false);
                                    }}
                                    aria-disabled={loading}
                                >
                                    RECIBIDO
                                </div>
                                <div
                                    className="status_box green d-flex justify-content-center
                 align-items-center text-white me-2"
                                    type="button"
                                    onClick={() => HandleStatus("Completada")}
                                >
                                    COMPLETADA
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div
                                    className="status_box red d-flex justify-content-center
                 align-items-center text-white"
                                    type="button"
                                    onClick={() => {
                                        setLoading(true);
                                        HandleStatus("Anulada");
                                        setLoading(false);
                                    }}
                                >
                                    ANULADA
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {showInfo && (
                <div
                    className="modal_bg d-flex justify-content-center 
          align-items-center"
                >
                    <div
                        className="modal"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog w-100">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                                        Detalles # WV{id.orderId}
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setShowInfo(false);
                                            // status.current = "";
                                        }}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="d-flex flex-wrap align-items-center mt-1">
                                        <p
                                            className=""
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Orden : WV{id.orderId}
                                        </p>
                                        <p
                                            className=" mt-1"
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Fecha : {id.date}
                                        </p>
                                        <p
                                            className=" mt-1"
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Beneficiario : {id.beneficiary}
                                        </p>
                                        <p
                                            className=" mt-1"
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Cantidad a enviar (EUR) : {id.amount}
                                        </p>
                                        <p
                                            className=" mt-1"
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Recibe : {id.gets}
                                        </p>
                                        <p
                                            className=" mt-1"
                                            style={{
                                                width: window.matchMedia("(max-width: 650px)").matches
                                                    ? "100%"
                                                    : "50%",
                                            }}
                                        >
                                            Total a pagar : {id.totalAmount}
                                        </p>
                                    </div>

                                </div>

                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setShowInfo(false);
                                            // status.current = "";
                                        }}
                                    >
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Transactions2;
